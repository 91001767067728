import React from 'react';

interface Props {
  size? : number;
}

const Corner: React.FC<Props> = (props: Props) => {
  const { size = 6 } = props;
  return (
    <>
      <svg
        viewBox="0 0 8 6"
        width={size * 8 / 6}
        height={size}

        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.18912 4.8764C3.58825 5.42946 4.41175 5.42946 4.81088 4.87641L7.1861 1.58521C7.6634 0.923842 7.19083 0 6.37522 0H1.62478C0.809174 0 0.336598 0.923841 0.813896 1.58521L3.18912 4.8764Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Corner;
