import React, {
  forwardRef, HTMLProps, FocusEvent, useState, ReactNode,
} from 'react';
import classNames from 'classnames';
import './Input.css';
import EyeOff from 'components/Icons/EyeOff/EyeOff';
import EyeOn from 'components/Icons/EyeOn/EyeOn';
import Spinner from '../../Spinner/Spinner';

export type InputProps = Props;

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  size?: 'medium' | 'big';
  isLoading?: boolean;
  isInvalid?: boolean;
  addonLeft?: ReactNode;
  addonRight?: ReactNode;
  onNativeChange?: (value: string, props: Partial<InputProps>) => void;
}

const Input = forwardRef<HTMLInputElement, Props>(({
  size = 'medium',
  type,
  isLoading,
  isInvalid,
  className,
  onNativeChange,
  disabled,
  addonLeft,
  addonRight,
  onFocus,
  onBlur,
  ...rest
}, ref) => {
  const [valueOnFocus, setValueOnFocus] = useState('');
  const [currentType, setCurrentType] = useState(type);
  const completeClassName = classNames([
    'geecko-input',
    isLoading ? 'geecko-input--loading' : '',
    isInvalid ? 'geecko-input--error' : '',
    addonLeft ? 'geecko-input__with-addon--left' : '',
    addonRight || type === 'password' ? 'geecko-input__with-addon--right' : '',
  ]);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValueOnFocus(value);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value !== valueOnFocus && onNativeChange) {
      onNativeChange(value, { ...rest });
    }
    if (onBlur) {
      onBlur(event);
    }
  };
  const handleTogglePasswordType = () => {
    setCurrentType(previous => previous === 'password' ? 'text' : 'password');
  }

  return (
    <div
      className={classNames(
        'geecko-input__wrapper',
        `geecko-input__wrapper--size-${size}`,
      )}
    >
      {addonLeft && (
        <div className="geecko-input__addon geecko-input__addon--left">{addonLeft}</div>
      )}
      {(addonRight || type === 'password') && (
        <div className="geecko-input__addon geecko-input__addon--right">
          {type !== 'password' && addonLeft}
          {type === 'password' && (
            <button type="button" className="geecko-input__close-button" tabIndex={-1} onClick={handleTogglePasswordType}>
              {currentType === 'password' ? <EyeOff /> : <EyeOn />}
            </button>
          )}
        </div>
      )}
      <input
        ref={ref}
        {...rest}
        className={`${completeClassName} ${className || ''}`}
        type={currentType}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {isLoading && (
        <div className="geecko-input__loading">
          <Spinner size={20} color="#898B94" />
        </div>
      )}
    </div>
  );
});

export default Input;
