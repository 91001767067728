import React, { HTMLProps } from 'react';
import './Spinner.css';

interface Props extends HTMLProps<HTMLDivElement> {
  size?: number;
  color?: string;
  center?: boolean;
  strokeWidth?: number;
}

const Spinner: React.FC<Props> = ({
  size, color, strokeWidth = 4, center = false, ...other
}) => (
  <div
    className="geecko-spinner"
    style={{
      width: size || 50,
      height: size || 50,
      color,
      margin: center ? 'auto' : 'initial',
    }}
    {...other}
  >
    <svg className="geecko-spinner__circle" viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth} />
    </svg>
  </div>
);

export default Spinner;
