/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { ReactNode, ChangeEvent, MouseEvent, KeyboardEvent, useRef, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Input from 'components/v2/Input/Input';
import { useDebounce } from '../../hooks/use-debounce';
import './AsyncSelect.css';
import Corner from '../Icons/Corner/Corner';

export interface PlainOption {
  value: string;
  label: string;
}

export type AsyncSelectLoadOptionsHandler<ValueType> = (searchQuery: string) => Promise<ValueType[]>;

export type AsyncSelectProps<T> = Props<T>;

interface Props<ValueType> {
  name?: string;
  size?: 'medium' | 'big';
  loadOptions?: AsyncSelectLoadOptionsHandler<ValueType>;
  value?: ValueType;
  options?: ValueType[];
  onChange: (value?: ValueType) => void;
  renderOption?: (option: ValueType) => ReactNode | null;
  loadOnInit?: boolean;
  initialQuery?: string;
  placeholder?: string;
  hideIfEmpty?: boolean;
  autoFocus?: boolean;
  isInvalid?: boolean;
  valueKey: keyof ValueType;
  labelKey: keyof ValueType;
  imageKey?: keyof ValueType;
  noOptionsPlaceholder?: string | ReactNode;
  creatable?: boolean;
  isDisabled?: boolean;
  async?: boolean;
  plainValue?: boolean;
}

function Select<ValueType extends {}>(props: Props<ValueType>) {
  const {
    value,
    initialQuery,
    loadOptions,
    loadOnInit,
    renderOption,
    onChange,
    placeholder,
    noOptionsPlaceholder,
    hideIfEmpty,
    autoFocus,
    valueKey,
    labelKey,
    imageKey,
    size = 'medium',
    creatable,
    isDisabled,
    async,
    options,
    plainValue,
    isInvalid,
    name,
  } = props;
  const intl = useIntl();
  if (async && !loadOptions) {
    throw new Error(intl.formatMessage({ id: 'app.select.async.required' }));
  }

  let initialQueryString = '';
  if (initialQuery) {
    initialQueryString = initialQuery;
  }

  const lastRequestId = useRef(nanoid());
  const inputRef = useRef<HTMLInputElement>(null);
  const [asyncOptions, setOptions] = useState<ValueType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputQuery, setInputQuery] = useState(initialQueryString);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const selectOptions = useMemo<ValueType[]>(() => {
    if (async) {
      return asyncOptions;
    }
    return (options || []).filter((item) => {
      if (!inputQuery) {
        return true;
      }
      if (typeof item[labelKey] === 'string') {
        // @ts-ignore
        return item[labelKey].includes(inputQuery);
      }
      return true;
    });
  }, [async, asyncOptions, options, inputQuery]);

  const optionsLabelNames = useMemo<string[]>(() => {
    // @ts-ignore
    return selectOptions.map((option) => option[labelKey] as string);
  }, [labelKey, selectOptions]);

  useEffect(() => {
    if (async && loadOnInit && loadOptions) {
      loadOptions('')
        .then((newOptions) => {
          setOptions(newOptions);
        })
        .catch(() => {
          //
        });
    }
  }, []);

  const handleFocus = () => {
    setIsOpen(true);
    handleLoadOptions();
  };
  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleSelect = (option: ValueType) => {
    // @ts-ignore
    if (option.disabled) {
      return;
    }
    // @ts-ignore
    onChange(plainValue ? option[valueKey] : option);
    if (async) {
      setOptions([]);
    }
    setSelectedIndex(-1);
    setInputQuery('');
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputQuery(inputValue);
  };

  const handleValueButtonClick = () => {
    setInputQuery('');
    setIsOpen(true);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      handleLoadOptions();
    }
  }, [isOpen]);

  const handleLoadOptions = () => {
    if (!loadOnInit && !isOpen) {
      return;
    }
    if (!loadOptions) {
      return;
    }
    const currentRequestId = nanoid();
    lastRequestId.current = currentRequestId;
    setIsLoading(true);
    loadOptions(debouncedQuery)
      .then((newOptions) => {
        if (lastRequestId.current === currentRequestId) {
          setIsLoading(false);
          setOptions(newOptions);
          if (!creatable) {
            setSelectedIndex(newOptions.length > 0 ? 0 : -1);
          } else {
            setSelectedIndex(0);
          }
        }
      })
      .catch(() => {
        if (lastRequestId.current === currentRequestId) {
          setIsLoading(true);
        }
      });
  };

  const debouncedQuery = useDebounce(inputQuery, 200);
  useEffect(() => {
    handleLoadOptions();
  }, [debouncedQuery]);

  const handleSelectOption = (option: ValueType) => {
    handleSelect(option);
    setIsOpen(false);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (creatable && selectedIndex === selectOptions.length) {
        handleCreateOption();
      } else if (selectedIndex >= 0 && selectedIndex < selectOptions.length) {
        const option = selectOptions[selectedIndex];
        // @ts-ignore
        if (!option.disabled) {
          handleSelect(option);
          if (inputRef && inputRef.current) {
            inputRef.current.blur();
          }
        }
      }
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const indexFix = creatable ? 0 : 1;
      if (selectedIndex < selectOptions.length - indexFix) {
        setSelectedIndex(selectedIndex + 1);
      }

      return;
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  const handleCreateOption = () => {
    handleSelectOption({
      [labelKey]: inputQuery,
    } as ValueType);
  };

  const currentValue: ValueType | undefined = useMemo(() => {
    if (async || !plainValue) {
      return value;
    }

    // @ts-ignore
    return options ? options.find((item) => item[valueKey] === value) : undefined;
  }, [options, async, value, valueKey, plainValue]);

  const currentPlaceholder = useMemo(() => {
    return currentValue ? currentValue[labelKey] : placeholder;
  }, [currentValue, placeholder, labelKey]);

  return (
    <div className={`geecko-async-select${isOpen ? ' geecko-async-select--open' : ''}`}>
      {currentValue && !isOpen && (
        <button
          name={name}
          className={classNames(
            'geecko-async-select__value-button',
            `geecko-async-select__value-button--size-${size}`,
            isInvalid ? 'geecko-async-select__value-button--is-invalid' : '',
          )}
          type="button"
          disabled={isDisabled}
          onClick={handleValueButtonClick}
        >
          {currentValue[labelKey]}
        </button>
      )}
      {(!value || isOpen) && (
        <Input
          ref={inputRef}
          size={size}
          value={inputQuery}
          disabled={isDisabled}
          isInvalid={isInvalid}
          name={name}
          // @ts-ignore
          placeholder={currentPlaceholder}
          isLoading={isLoading}
          autoFocus={autoFocus}
          onKeyDown={handleKeyPress}
          onKeyPress={handleKeyPress}
          onChange={handleChangeInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {isOpen && (!hideIfEmpty || inputQuery) && (
        <div className="geecko-async-select__options">
          {selectOptions.length > 0 && inputQuery && (
            <div className="geecko-multi-select__options-placeholder">
              {intl.formatMessage({ id: 'app.select.async.choosevariant' })}
            </div>
          )}
          {selectOptions.length > 0 && (
            <>
              {selectOptions.map((option, optionIndex) => (
                <AsyncSelectOptionBlock<ValueType>
                  // @ts-ignore
                  key={option[valueKey]}
                  valueKey={valueKey}
                  labelKey={labelKey}
                  imageKey={imageKey}
                  option={option}
                  isDisabled={(option as any).disabled}
                  isSelected={optionIndex === selectedIndex}
                  isCurrent={
                    currentValue &&
                    (currentValue[valueKey] === option[valueKey] || currentValue[labelKey] === option[labelKey])
                  }
                  renderOption={renderOption}
                  onSelect={handleSelectOption}
                />
              ))}
            </>
          )}
          {!creatable && selectOptions.length === 0 && !isLoading && inputQuery && (
            <div className="geecko-async-select__options-placeholder">
              {noOptionsPlaceholder || intl.formatMessage({ id: 'app.select.async.noresult' })}
            </div>
          )}
          {!creatable && selectOptions.length === 0 && !isLoading && !inputQuery && noOptionsPlaceholder && (
            <div className="geecko-multi-select__options-placeholder">{noOptionsPlaceholder}</div>
          )}
          {creatable && inputQuery && optionsLabelNames.indexOf(inputQuery) < 0 && (
            <button
              type="button"
              className={`geecko-async-select__option${
                selectedIndex === selectOptions.length ? ' geecko-async-select__option--selected' : ''
              }`}
              onMouseDown={handleCreateOption}
            >
              {intl.formatMessage({ id: 'app.add' })}{' '}
              <span className="geecko-async-select__create-option">{inputQuery}</span>
            </button>
          )}
          {selectOptions.length === 0 && isLoading && (
            <div className="geecko-async-select__options-placeholder">{intl.formatMessage({ id: 'app.loading' })}</div>
          )}
        </div>
      )}
      {!async && (
        <div className="geecko-async-select__arrow">
          <Corner />
        </div>
      )}
    </div>
  );
}

interface OptionProps<ValueType> {
  option: ValueType;
  valueKey: keyof ValueType;
  labelKey: keyof ValueType;
  imageKey?: keyof ValueType;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCurrent?: boolean;
  renderOption?: (option: ValueType) => ReactNode | null;
  onSelect: (option: ValueType) => void;
}

function AsyncSelectOptionBlock<ValueType>(props: OptionProps<ValueType>) {
  const { option, renderOption, isSelected, isDisabled, isCurrent, onSelect, labelKey, imageKey } = props;

  const handleMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if (option.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onSelect(option);
    }
  };

  return (
    <button
      type="button"
      className={classNames(
        'geecko-async-select__option',
        { 'geecko-async-select__option--selected': isSelected },
        { 'geecko-async-select__option--current': isCurrent },
      )}
      disabled={isDisabled}
      onMouseDown={handleMouseDown}
    >
      {renderOption && renderOption(option)}
      {!renderOption && (
        <>
          {imageKey && option[imageKey] && (
            <div className="geecko-async-select__option-image">
              <img
                // @ts-ignore
                src={option[imageKey]}
                alt=""
              />
            </div>
          )}
          <div>{option[labelKey]}</div>
        </>
      )}
    </button>
  );
}

export default Select;
