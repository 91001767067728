import React from 'react';

interface Props {
  size? : number;
}

const EyeOn: React.FC<Props> = (props: Props) => {
  const { size = 18 } = props;
  return (
    <>
      <svg
        viewBox="0 0 20 20"
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.833496 10.0002C0.833496 10.0002 4.16683 3.3335 10.0002 3.3335C15.8335 3.3335 19.1668 10.0002 19.1668 10.0002C19.1668 10.0002 15.8335 16.6668 10.0002 16.6668C4.16683 16.6668 0.833496 10.0002 0.833496 10.0002Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
};

export default EyeOn;
